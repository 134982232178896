import React, { useState, useEffect } from "react";
import { StyleSheet, css } from "aphrodite";
import StepHeader from "./step-header.component.js";
import { CpSelectSingle } from "canopy-styleguide!sofe";
import { useRoleTemplates } from "./role-templates.resource.js";
import { useHasAccess } from "cp-client-auth!sofe";

export default function StepThree({ setRole }) {
  const { roleTemplates, loading } = useRoleTemplates();
  const [assignedRole, setAssignedRole] = useState();
  const onlyAdmin = !useHasAccess(["company_settings_roles_and_permissions_view"]);
  const adminRoleTemplate = roleTemplates.find((t) => t.id === "__admin__");
  const permissionSetName = "Permission Set";

  useEffect(() => {
    if (onlyAdmin && adminRoleTemplate) {
      setAssignedRole(adminRoleTemplate);
      setRole(adminRoleTemplate.id);
    }
  }, [onlyAdmin, adminRoleTemplate]); // eslint-disable-line react-hooks/exhaustive-deps
  // Lint-TODO: missing dependency: 'setRole'

  return (
    <div>
      <StepHeader
        title={`Select what ${permissionSetName.toLowerCase()} they should have.`}
        description={`You can change these at any time or create custom ${permissionSetName.toLowerCase()}s in your settings area.`}
        imgUrl={"https://cdn.canopytax.com/static/licenses/license-permissions.svg"}
      />
      <div className={css(styles.body)}>
        <span>{permissionSetName}</span>
        <CpSelectSingle
          loading={loading}
          value={assignedRole}
          data={onlyAdmin ? [adminRoleTemplate] : roleTemplates}
          onChange={(role) => {
            setAssignedRole(role);
            setRole(role.id);
          }}
          triggerIsBlock
          fixedContent
          className="cp-mt-8"
          contentWidth="block"
        />
      </div>
    </div>
  );
}

export const styles = StyleSheet.create({
  body: {
    padding: "24px 48px",
  },
});
