import React from "react";
import StepHeader from "./step-header.component.js";
import License from "./license.component.js";
import { useWithUserAndTenant } from "cp-client-auth!sofe";

const addonLicenses = ["taxResolution", "transcripts", "irsNotices"];

export const legacyLicenseTypes = ["practiceManagement"];

export const toggledLicenseTypes = ["clientManagement", "timeAndBilling", "documentManagement", "workflow"];

export default function StepTwo(props) {
  const { email, ...rest } = props;
  const [, tenant] = useWithUserAndTenant();

  const coreLicenses = tenant?.licenses?.clientManagement?.totalAssigned > 0 ? toggledLicenseTypes : legacyLicenseTypes;

  return (
    <div>
      <StepHeader
        title={`Assign licenses to ${email}`}
        description="To manage your licenses, go to Settings > Account Management"
        imgUrl={"https://cdn.canopytax.com/static/licenses/assign-licenses.svg"}
      />
      <div className="cp-p-24">
        {!!tenant &&
          [...coreLicenses, ...addonLicenses].map((licenseName) => (
            <License licenseName={licenseName} tenant={tenant} key={licenseName} {...rest} />
          ))}
      </div>
    </div>
  );
}
