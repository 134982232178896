import React, { useState, useEffect } from "react";
import { StyleSheet, css } from "aphrodite";
import { CpButton, CpWell, constants } from "canopy-styleguide!sofe";
import { getAvailableLicenseCount, hasPurchasedLicense } from "cp-client-auth!sofe";
const licenseIcons = {
  practiceManagement: "empty-states/es_clients",
  clientManagement: "empty-states/es_clients",
  taxResolution: "licenses/bw-tr-icon",
  transcripts: "licenses/bw-ts-icon",
  irsNotices: "licenses/bw-nt-icon",
  timeAndBilling: "empty-states/es_billing_invoice",
  documentManagement: "empty-states/folder-closed",
  workflow: "empty-states/es_checkmark",
  default: "empty-states/es_clients",
};
const cmModules = ["timeAndBilling", "documentManagement", "workflow"];

export default function License(props) {
  const [showMoreLicenses, setShowMoreLicenses] = useState(false);
  const {
    selectedLicenses,
    licenseName,
    toggleLicense,
    tenant: { licenses, client_limit, free_transcripts, pro_cm },
  } = props;
  const icon = licenseIcons[licenseName] || licenseIcons.default;
  const isPracticeManagement = licenseName === "practiceManagement";
  const isClientManagement = licenseName === "clientManagement";
  const wellStyle = {
    light: 1,
    medium: 2,
  };
  const numAvailable = getAvailableLicenseCount(licenseName, licenses, client_limit, free_transcripts);

  useEffect(() => {
    if (
      (isPracticeManagement || isClientManagement) &&
      !!client_limit &&
      numAvailable > 0 &&
      !selectedLicenses.includes(licenseName)
    ) {
      toggleLicense(licenseName);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // Lint-TODO: missing dependencies: 'client_limit', 'isClientManagement', 'isPracticeManagement', 'licenseName', 'numAvailable', 'selectedLicenses', and 'toggleLicense'

  const isLicensePurchased = (type) => {
    return hasPurchasedLicense(type, licenses, client_limit, free_transcripts);
  };

  const shouldHideTile = () => {
    if (isPracticeManagement || isClientManagement) {
      return numAvailable <= 0;
    }
    if (licenseName === "transcripts" && free_transcripts) {
      return false;
    }
    return !isLicensePurchased(licenseName);
  };

  const userFriendlyName = () => {
    const name = constants.licenseMap[licenseName];
    if (isPracticeManagement) {
      return `${name} ${pro_cm ? " Professional" : " Standard"}`;
    }
    return name;
  };

  const toggleShowMoreLicenses = (evt) => {
    evt.stopPropagation();
    setShowMoreLicenses((prevState) => !prevState);
  };

  const isSelectedLicenseDependency = () => {
    // cannot toggle license off if it is required for a selected license
    if (isClientManagement && cmModules.some((module) => selectedLicenses.includes(module))) {
      return true;
    }
    return false;
  };

  const requiresDependencyLicense = () => {
    // cannot toggle license on if it requires another license that isnt selected
    if (cmModules.includes(licenseName) && !selectedLicenses.includes("clientManagement")) {
      return true;
    }
    return false;
  };

  const disabled = numAvailable <= 0 || props.disabled || requiresDependencyLicense() || isSelectedLicenseDependency();
  const selected = selectedLicenses.includes(licenseName);
  const isDependentLicense = [...cmModules].includes(licenseName);

  if (shouldHideTile()) {
    return null;
  }
  return (
    <CpWell
      onClick={() => {
        if (!disabled && !showMoreLicenses) {
          toggleLicense(licenseName);
        }
      }}
      level={disabled ? wellStyle.medium : wellStyle.light}
      className={`${css(styles.license)} ${isDependentLicense ? css(styles.dependentLicenseTile) : ""}`}
      style={{
        cursor: disabled ? "initial" : "pointer",
        border: "1px solid var(--cps-color-well-border)",
      }}
    >
      <div className={css(styles.unexpandedContent)}>
        <div className={`${css(styles.licenseRight)}`}>
          <label className="cps-checkbox cps-margin-right-16">
            <input
              type="checkbox"
              checked={selected}
              onClick={(e) => {
                e.stopPropagation();
              }}
              disabled={disabled}
            />
            <span />
          </label>
          <div className={css(styles.imgContainer)}>
            <img className={`${css(styles.licenseImg)}`} src={`https://cdn.canopytax.com/static/${icon}.svg`} />
          </div>
          <div>
            <div className="cps-wt-semibold">{userFriendlyName()}</div>
            <div>
              {numAvailable === Infinity ? (
                <span className="cps-wt-semibold">Unlimited</span>
              ) : (
                <>
                  <span className="cps-wt-semibold">{numAvailable}</span> available
                </>
              )}
            </div>
          </div>
        </div>
        {numAvailable < 1 && !selected && (
          <CpButton btnType="flat" onClick={toggleShowMoreLicenses}>
            {showMoreLicenses ? "Done" : "Get more licenses"}
          </CpButton>
        )}
      </div>
      {showMoreLicenses && (
        <>
          <hr className={css(styles.divider)} />
          <div className={css(styles.needAFewMore)}>
            <div className="cps-body cps-wt-bold">Need a few more licenses?</div>
            <div className="cps-margin-top-8">No problem, one of our product specialists will be in touch soon.</div>
            <div>
              You can also get a hold of us by calling <span className="cps-wt-bold">{constants.canopyPhone}</span>.
            </div>
          </div>
        </>
      )}
    </CpWell>
  );
}

export const styles = StyleSheet.create({
  loaderBox: {
    padding: "2.4rem",
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  license: {
    padding: "1.6rem 1.6rem",
    marginBottom: "1.6rem",
    borderRadius: ".5rem",
    width: "100%",
    marginLeft: "0",
    color: "var(--cps-color-primary-text)",
  },
  dependentLicenseTile: {
    width: "calc(100% - 2.4rem)",
    marginLeft: "24px",
  },
  unexpandedContent: {
    padding: "0 .8rem",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  divider: {
    margin: "1.6rem 0",
  },
  licenseImg: {
    maxHeight: "4.8rem",
    maxWidth: "4.8rem",
  },
  licenseRight: {
    display: "flex",
    alignItems: "center",
  },
  needAFewMore: {
    textAlign: "center",
  },
  imgContainer: {
    // regardless of the image width, we need to take up the same amount of space so the text is aligned.
    // See https://canopytax.atlassian.net/browse/CUS-31
    width: "6.2rem",
    marginRight: "2.4rem",
    maxHeight: "4rem",
  },
});
